/// <reference path="importer.ts" />
$().ready(() => {
    /**
     * Class is responsible for handling displaying quotes
     * It also handles the carousel and manual actions of the user
     * Initialization is based on a Jquery object that contains all .quote items
     */
    class QuoteManager {
        /**
         * List of quotes on which this class will iterate
         */
        private quotes: Array<HTMLElement>;
        /**
         * starts at -1, first quote will be index 0
         * @type {number}
         */
        private activeQuoteIndex: number = -1;
        /**
         * An array of key (index number) => pair (promise on transition) promises
         */
        private promises: Array<JQueryDeferred<any>>;
        /**
         * Number of seconds a quote is displayed
         * @type {number}
         */
        private secondsDisplayed: number = 5;
        /**
         * If true, fading is happening, so the user isn't allowed any actions
         * @type {boolean}
         */
        private busyTransitioning: boolean = false;
        /**
         * Currently displayed quote
         * @type {any}
         */
        private currentQuote: HTMLElement = undefined;
        /**
         * Speed of the animation, in ms
         * The speed should include the total length of fade out + fade in
         * @type {number}
         */
        private animationSpeed: number = 500;//in ms
        /**
         * Default constructor
         * @param container The Jquery object which contains all the .quote elements
         * @param secondsDisplayed optional, if omitted default value will be applied
         */
        constructor(private container: JQuery, secondsDisplayed?: number) {
            this.quotes = this.container.find('.quote').toArray();
            this.promises = [];
            if (secondsDisplayed) {
                this.secondsDisplayed = secondsDisplayed;
            }
        }

        /**
         * Returns the currenly displayed quote
         * @returns {JQuery}
         */
        private getCurrentQuote(): HTMLElement {
            return this.quotes[this.activeQuoteIndex];
        }

        /**
         * Increments the index and starts the transition animation
         * @returns {JQueryPromise<U>}
         */
        private transitionToNext(): JQueryPromise<void> {
            if (this.activeQuoteIndex + 1 == this.quotes.length) {
                this.activeQuoteIndex = -1;
            }
            ++this.activeQuoteIndex;
            let nextQuote = this.getCurrentQuote();
            this.busyTransitioning = true;
            return this.fadeOut(this.currentQuote)
                .then(() => {
                    return this.fadeIn(nextQuote);
                })
                .then(() => {
                    //Done
                    this.busyTransitioning = false;
                })
        }

        /**
         * Decrements the index and starts the transition
         * @returns {JQueryPromise<U>}
         */
        private transitionToPrevious(): JQueryPromise<void> {
            if (this.activeQuoteIndex - 1 < 0) {
                this.activeQuoteIndex = this.quotes.length - 1;
            } else {
                --this.activeQuoteIndex;
            }

            let previousQuote = this.getCurrentQuote();
            return this.fadeOut(this.currentQuote)
                .then(() => {
                    return this.fadeIn(previousQuote);
                })
                .then(() => {
                    //done
                })
        }

        /**
         * Fades in the quote object parameter
         * This method also changes the current quote
         * @param quote Jquery .quote object
         * @returns {JQueryDeferred<T>} resolved when fading is done
         */
        private fadeIn(quote: HTMLElement): JQueryDeferred<boolean> {
            let promise: JQueryDeferred<boolean> = $.Deferred();
            $(quote).removeClass('faded');
            this.currentQuote = quote;
            setTimeout(() => {
                promise.resolve(true);
            }, this.animationSpeed);
            return promise;
        }

        /**
         * Fades out the quote object parameter
         * @param quote Jquery .quote object
         * @returns {JQueryDeferred<T>} resolved when fading is done
         */
        private fadeOut(quote: HTMLElement): JQueryDeferred<boolean> {
            let promise: JQueryDeferred<boolean> = $.Deferred();
            $(quote).addClass('faded');
            setTimeout(() => {
                promise.resolve(true);
            }, this.animationSpeed);
            return promise;
        }

        /**
         * Loops over all quotes
         * This method should be called when loading of the page is done
         */
        public loop(): void {
            this.transitionToNext()
                .then(() => {
                    //Make a promise that will wait a number of seconds
                    let promise: JQueryDeferred<boolean> = $.Deferred();
                    this.promises[this.activeQuoteIndex] = promise;
                    setTimeout(() => {
                        promise.resolve();
                    }, this.secondsDisplayed * 1000);
                    return promise.promise();
                })
                .then(() => {
                    //After quote has been displayed for a number of seconds,
                    //restart
                    this.loop();
                })
        }

        /**
         * Interface callback
         * Displays the next quote
         * This method will initiate a loop
         */
        public next(): void {
            if (!this.busyTransitioning) {
                /*
                 Check if we have some pending promises
                 If we do that means that a quote is getting displayed and that the loop
                 is waiting for that promise to resolve before displaying the next one
                 */
                if (this.promises[this.activeQuoteIndex]) {
                    //reject and thus break the loop
                    this.promises[this.activeQuoteIndex].reject();
                }
                //As we moved the index the the next, we may resolve looping
                //calling loop will display the next item
                this.loop();
            }
        }

        /**
         * Interface callback
         * Displays the previous quote
         * This method will initiate a loop
         */
        public previous(): void {
            //for explanation, see next() method
            if (!this.busyTransitioning) {
                if (this.promises[this.activeQuoteIndex]) {
                    this.promises[this.activeQuoteIndex].reject();
                }
                if (this.activeQuoteIndex == 0) {
                    this.activeQuoteIndex = this.quotes.length - 2;
                    //In loop, index will be length -1  => last one
                } else {
                    this.activeQuoteIndex = this.activeQuoteIndex - 2;
                    //In loop index will be index -1    => previous one
                }
                this.loop();
            }
        }
    }

    let quoteManager = new QuoteManager($('#quote-container'), 10);
    quoteManager.loop();        //Start the show

    $('#quote-next').click(() => {
        quoteManager.next();
    });

    $('#quote-previous').click(() => {
        quoteManager.previous();
    });
});

